import { Controller } from "stimulus"

export default class extends Controller {
  connect() {

    const user_country = document.querySelector('#benefit_related_country_ids');
    if(user_country) {
      const choices3 = new Choices(user_country, {
        delimiter: ',',
        editItems: true,
        removeItemButton: true,
        placeholder: false,
      }); 
    }
  }
}