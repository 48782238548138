import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    window.onscroll = function() {myFunction()};

    // Get the header
    let header = this.element;
    let width = document.getElementById('benefit-content').offsetWidth;
    
    // Get the offset position of the navbar
    let sticky = header.offsetTop;
    
    // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
    function myFunction() {
      if (window.pageYOffset > 100) {
        header.classList.add("c-sticky-header--is-sticky");
        header.classList.remove("hidden");
        header.style.width = `${width}px`;
      } else {
        header.classList.remove("c-sticky-header--is-sticky");
        header.classList.add("hidden");
      }
    }
  }
}