import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    locations: Array
  }

  connect() {    
    console.log(this.locationsValue);

    // The map, centered at the first location
    var map = new google.maps.Map(document.getElementById('map'), {
        zoom: 12,
        center: {lat: 25.2761838, lng: 55.2883546}
    });

    // Add markers for each location
    this.locationsValue.forEach(function(location) {
        var marker = new google.maps.Marker({
            position: {lat: location.lat, lng: location.lng},
            map: map,
            title: location.title
        });

        marker.addListener('click', function() {
          // Close any open info windows

          // Create an info window with the title
          var infoWindow = new google.maps.InfoWindow({
              content: '<strong>' + location.title + '</strong>'
          });

          // Open the info window on the marker
          infoWindow.open(map, marker);
      });
    });
  }
}