import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    document.querySelectorAll('.page-update').forEach((element) => {
      element.addEventListener('change', (event) => {
        element.closest('form').submit();
      });
    });
  }
}