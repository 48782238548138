import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    let slidesPerView = parseInt(this.element.getAttribute('data-slidesPerView'));
    const spaceBetween = parseInt(this.element.getAttribute('data-spaceBetween'));

    const swiperElement = this.element.querySelector('.mySwiper')

    if (window.innerWidth <= 768) {
      slidesPerView = 1;
    } else {
      slidesPerView = parseInt(this.element.getAttribute('data-slidesPerView'));
    }

    var swiper = new window.Swiper(swiperElement, {
      slidesPerView: slidesPerView,
      spaceBetween: spaceBetween,
      loop: false,
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
      navigation: {
        nextEl: this.element.querySelector('.swiper-next'),
        prevEl: this.element.querySelector('.swiper-prev'),
      },
    });


    swiper.on("keyPress", (swiper, keyCode) => {
      switch (keyCode) {
        case 38:
          swiper.slidePrev();
          break;
        case 40:
          swiper.slideNext();
          break;
      }
    });
  }
}
