import { Controller } from "stimulus";
import validator from 'validator';

export default class extends Controller {
  static targets = ["firstName", "lastName", "country"];

  validations ={
    'firstName': [
      {
        'type': 'presence',
        'message': 'Enter your first name.'
      }
    ],
    'lastName': [
      {
        'type': 'presence',
        'message': 'Enter your last name.'
      }
    ],
    'country': [
      {
        'type': 'presence',
        'message': 'Enter your country.'
      }
    ],
  }

  connect() {
    const tos = document.getElementById("tos");
    const _this = this;
  }


  submit(event) {
    let _this = this;

    let isValid = []
    this.constructor.targets.forEach( (item) => {
      isValid.push(_this.validateField(item))
    })

    if(isValid.indexOf(false) > -1) {
      event.preventDefault(); 
      return true;
    } else {
      return true;
    }
  }

  validateField(item) {
    let isValid = true;
    let target = this[`${item}Target`];

    if(Array.isArray(this.validations[item])) {
      let validationText = target.parentNode.querySelector('.c-validation-text--error');
      this.validations[item].forEach((validation) => {
        if(validation.type == 'email') {
          if(!validator.isEmail(target.value)) {
            target.classList.add('border-brilliantRose');
            target.classList.remove('border-mountainMist');
            validationText.innerText = validation.message;
            isValid = false;
            
          } else {
            target.classList.remove('border-brilliantRose');
            target.classList.add('border-mountainMist');
            validationText.innerText = '';
          }
        }
        else if(validation.type == 'presence') {
          if(!target.value.length > 0) {
            target.classList.add('border-brilliantRose');
            target.classList.remove('border-mountainMist');
            validationText.innerText = validation.message;
            isValid = false;
          } else {
            target.classList.remove('border-brilliantRose');
            target.classList.add('border-mountainMist');
            validationText.innerText = '';
          }
        } else if(validation.type == 'checked') {
          if(!target.checked) {
            target.classList.add('border-brilliantRose');
            target.classList.remove('border-mountainMist');
            validationText.innerText = validation.message;
            isValid = false;
          } else {
            target.classList.remove('border-brilliantRose');
            target.classList.add('border-mountainMist');
            validationText.innerText = '';
          }
        }
      })
    };

    return isValid;
  }
}