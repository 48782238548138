import { Controller } from "stimulus"

// Connects to data-controller="dropdown"
export default class extends Controller {
  connect() {
  }

  toggleDropdown() {
    this.element.querySelector('.dropdown-content').classList.toggle('hidden');

    if(this.element.querySelector('.dropdown-arrow')) {
      this.element.querySelector('.dropdown-arrow').classList.toggle('!rotate-180')
    }
  }
}
