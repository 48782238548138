import { Controller } from "stimulus"

export default class extends Controller {
  connect() {    
    const element = document.querySelector('#reward_category_selected_rewards');
    if(element) {
      const choices1 = new Choices(element, {
        delimiter: ',',
        editItems: true,
        removeItemButton: true,
        placeholder: false,
      }); 
    }

    const reward_buckets = document.querySelector('#reward_buckets');
    if(reward_buckets) {
      const reward_buckets_elment = new Choices(reward_buckets, {
        delimiter: ',',
        editItems: true,
        removeItemButton: true,
        placeholder: false,
      }); 
    }

    let benefit_products = document.querySelector('#benefit_products');
    if(benefit_products) {
      setTimeout(function() {
        benefit_products = new Choices(benefit_products, {
          delimiter: ',',
          editItems: true,
          removeItemButton: true,
          placeholder: false,
        }); 
      },500)
    }

    const ygag = document.querySelector('#reward_ygag');
    if(ygag) {
      const choices2 = new Choices(ygag, {
        delimiter: ',',
        editItems: true,
        removeItemButton: true,
        placeholder: false,
      }); 
    }

    const benefitCountries = document.querySelector('#benefit_countries');
    if(benefitCountries) {
      const choices2 = new Choices(benefitCountries, {
        delimiter: ',',
        editItems: true,
        removeItemButton: true,
        placeholder: false,
      }); 
    }

    const user_countries = document.querySelector('#user_countries');
    if(user_countries) {
      const choices2 = new Choices(user_countries, {
        delimiter: ',',
        editItems: true,
        removeItemButton: true,
        placeholder: false,
      }); 
    }

    const widget_categoies = document.querySelector('#widget_categoies');
    if(widget_categoies) {
      new Choices(widget_categoies, {
        delimiter: ',',
        editItems: true,
        removeItemButton: true,
        placeholder: false,
      }); 
    }

    const country_categoies = document.querySelector('#country_categoies');
    if(country_categoies) {
      new Choices(country_categoies, {
        delimiter: ',',
        editItems: true,
        removeItemButton: true,
        placeholder: false,
      }); 
    }

    this.widget_users_selector = document.querySelector('#widget_users');
    if(this.widget_users_selector) {
      this.widget_users = new Choices(this.widget_users_selector, {
        delimiter: ',',
        editItems: true,
        removeItemButton: true,
        placeholder: false,
        selectAll: true,
        fuseOptions: {
          includeScore: true,
          useExtendedSearch: true,
          findAllMatches: true,
          ignoreLocation: true,
          fieldNormWeight: 0.6,
          distance: 500
        },
      }); 
    }


    this.widget_products_selector = document.querySelector('#widget_products');
    if(this.widget_products_selector) {
      this.widget_products = new Choices(this.widget_products_selector, {
        delimiter: ',',
        editItems: true,
        removeItemButton: true,
        placeholder: false,
        selectAll: true,
        fuseOptions: {
          includeScore: true,
          useExtendedSearch: true,
          findAllMatches: true,
          ignoreLocation: true,
          fieldNormWeight: 0.6,
          distance: 500
        },
      }); 
    }


    let excluded_customers = document.querySelector('#excluded_customers');
    if(excluded_customers) {
      new Choices(excluded_customers, {
        delimiter: ',',
        editItems: true,
        removeItemButton: true,
        placeholder: false,
        selectAll: true,
        fuseOptions: {
          includeScore: true,
          useExtendedSearch: true,
          findAllMatches: true,
          ignoreLocation: true,
          fieldNormWeight: 0.6,
          distance: 500
        },
      }); 
    }


    const widget_benefits = document.querySelector('#widget_benefits');
    if(widget_benefits) {
      new Choices(widget_benefits, {
        delimiter: ',',
        editItems: true,
        removeItemButton: true,
        placeholder: false,
        fuseOptions: {
          includeScore: true,
          useExtendedSearch: true,
          findAllMatches: true,
          ignoreLocation: true,
          fieldNormWeight: 0.6,
          distance: 500
        },
      }); 
    }

    window.widget_users = this.widget_users;
    

    flatpickr(".flatpickr", {
    });
  }

  selectAllCustomers() {
    let _this = this;
    let items = []
    this.widget_users._presetChoices.forEach((item) => {
      items.push(item.value)
    })
    _this.widget_users.setChoiceByValue(items[0]);
  }

  deselectAllCustomers() {
    this.widget_users.removeActiveItems()
  }
}