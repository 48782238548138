import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    ratio: String
  }

  connect() {
    // vars
    let result = this.element.querySelector('.result'),
    img_result = this.element.querySelector('.img-result'),
    save = this.element.querySelector('.save'),
    cropped = this.element.querySelector('.cropped'),
    upload = this.element.querySelector('.file-input'),
    cropper = '';

    let _this = this;
    // on change show image with crop options
    upload.addEventListener('change', (e) => {
      if (e.target.files.length) {
        // start file reader
        const reader = new FileReader();
        reader.onload = (e)=> {
          if(e.target.result){
            // create new image
            let img = document.createElement('img');
            img.id = 'image';
            img.src = e.target.result
            // clean result before
            result.innerHTML = '';
            // append new image
            result.appendChild(img);
            // show save btn and options
            save.classList.remove('hide');
            // init cropper
            cropper = new window.Cropper(img, {
              aspectRatio: _this.ratioValue == 'square' ? 1/ 1 : 16 / 9,
              minCropBoxWidth: 720
            });
          }
        };
        reader.readAsDataURL(e.target.files[0]);
      }
    });

    // save on click
    save.addEventListener('click',(e)=>{
      e.preventDefault();
      // get result to data uri
      let imgSrc = cropper.getCroppedCanvas({
      }).toDataURL();

      this.element.querySelector('.file_input').value = imgSrc;
      this.element.querySelector('.image-logo-submit').click();
    });
  }

}