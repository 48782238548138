import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "checkbox", "input" ]
  connect() {
    let _this = this;
    this.checkboxTarget.addEventListener("change", () => {
      _this.handleCheckboxChange();
    });

    _this.handleCheckboxChange();
  }

  handleCheckboxChange() {
    let _this = this;
    if(_this.checkboxTarget.checked) {
      _this.inputTarget.classList.remove("hidden");
    } else {
      _this.inputTarget.classList.add("hidden");
    }
  }
}