import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['title', 'content', 'divider', 'arrow']

  connect() {
  }


  toggle() {
    //this.tabTargets[this.index].classList.add('font-medium');
    this.contentTarget.classList.toggle('hidden');
    if(this.dividerTarget) {
      this.dividerTarget.classList.toggle('hidden');
    }
    this.arrowTarget.classList.toggle('rotate-180');
  }
}