import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    let _this = this;
    const chartData = JSON.parse(this.element.getAttribute("data-chart"));
    console.log(chartData);

    var ctx = document.getElementById('myChart');
    this.myChart = new Chart(ctx, {
        type: 'bar',
        options: {
          scales: {
              yAxes: [{
                  ticks: {
                      beginAtZero: true
                  }
              }]
          }
        },
        data: {
            labels: chartData.map(x => x.label),
            datasets: [{
                label: 'No. of claims',
                backgroundColor: '#6C4EEA',
                data: chartData.map(x => x.value),
            }]
        },
    });
  }

  disconnect() {
    this.myChart.destroy();
  }
}
