import { Controller } from "stimulus";
import validator from 'validator';

export default class extends Controller {
  static targets = ["email"];

  validations ={
    'email': [
      {
        'type': 'email',
        'message': 'Enter your personal email.'
      }
    ]
  }

  connect() {
    const tos = document.getElementById("tos");
    const _this = this;

    //tos.addEventListener('change', function(e){
    //  _this.checkCheckboxesState(); 
    //});
  }

  checkCheckboxesState() {
    const tos = document.getElementById("tos");
    var element = document.getElementById('submit-form')
    if (tos.checked  ){
      element.disabled = null;
      element.classList.remove('opacity-50');
    } else {
      element.classList.add('opacity-50');
    }
  }

  submit(event) {
    let _this = this;

    let isValid = []
    this.constructor.targets.forEach( (item) => {
      isValid.push(_this.validateField(item))
    })

    console.log(isValid);
    console.log(isValid.indexOf(false));
    if(isValid.indexOf(false) > -1) {
      event.preventDefault(); 
    } else {
      return true;
    }
  }

  validateField(item) {
    let isValid = true;
    let target = this[`${item}Target`];

    if(Array.isArray(this.validations[item])) {
      let validationText = target.parentNode.querySelector('.c-validation-text--error');
      this.validations[item].forEach((validation) => {
        if(validation.type == 'email') {
          if(!validator.isEmail(target.value)) {
            target.classList.add('border-brilliantRose');
            target.classList.remove('border-mountainMist');
            validationText.innerText = validation.message;
            isValid = false;
          } else {
            target.classList.remove('border-brilliantRose');
            target.classList.add('border-mountainMist');
            validationText.innerText = '';
          }
        }
        else if(validation.type == 'presence') {
          if(!target.value.length > 0) {
            target.classList.add('border-brilliantRose');
            target.classList.remove('border-mountainMist');
            validationText.innerText = validation.message;
            isValid = false;
          } else {
            target.classList.remove('border-brilliantRose');
            target.classList.add('border-mountainMist');
            validationText.innerText = '';
          }
        } else if(validation.type == 'checked') {
          if(!target.checked) {
            target.classList.add('border-brilliantRose');
            target.classList.remove('border-mountainMist');
            validationText.innerText = validation.message;
            isValid = false;
          } else {
            target.classList.remove('border-brilliantRose');
            target.classList.add('border-mountainMist');
            validationText.innerText = '';
          }
        }
      });
    };

    return isValid;
  }
}