import { Controller } from 'stimulus';

// Connects to data-controller="list-actions"
export default class extends Controller {
  static values = {
    setStatusUrl: String,
    currentStatus: String,
    pageRecords: Number,
  }

  static targets = ["status"]

  connect() {
    let _this = this;
    this.assetSelectCheckboxes
    this.assetSelectCheckboxes = document.querySelectorAll('.c-checkbox--list');
    this.selectAllCheckbox = document.querySelector('.c-checkbox--select-all');

    this.assetSelectCheckboxes.forEach((item)=> {
      item.addEventListener('change', function(){
        _this.displayBatchActionBar();
      });
    });

    if(this.selectAllCheckbox) {
      this.selectAllCheckbox.addEventListener('change', function(){
        if(_this.selectAllCheckbox.checked) {
            _this.selectAll(true, true);
            _this.displayBatchActionBar();
        } else {
          _this.selectAll(_this.selectAllCheckbox.checked, true);
          _this.displayBatchActionBar();
        }
      });
    }
  }

  toggleSelectAllModal() {
    this.element.querySelector('#select-modal').classList.toggle('hidden');
    if(this.selectAllCheckbox) {
      this.selectAllCheckbox.checked = false;
    }
  }

  selectAll(checked, withoutModal = false) {
    this.assetSelectCheckboxes.forEach((item)=> {
      item.checked = checked;
    });

    this.displayBatchActionBar();
      this.element.querySelector('#batch-status-change').classList.toggle('hidden');
  }

  makeSelection() {
    if(this.element.querySelector('input[name="list-select-logic"]:checked').value == 'radio_select_page') {
      this.selectAll(true, false);
    } else {
      this.selectAllWithHidden();
    }
  }

  selectAllWithHidden() {
    this.assetSelectCheckboxes.forEach((item)=> {
      item.checked = true;
    });
    this.displayBatchActionBar(true);
    this.element.querySelector('#select-modal').classList.toggle('hidden');
  }

  displayBatchActionBar(withHidden = false) {
       let selectedItems = [];
      let selectedValues = [];
      this.assetSelectCheckboxes.forEach((item)=> {
        if(item.checked) {
          selectedItems.push(item.value)
        }
      });
  
      if(selectedItems.length > 0) {
        if(document.getElementById('batch-status-change')) {
          document.getElementById('batch-status-change').classList.remove('hidden');
        }
        let queryParam = `?status=${this.currentStatusValue}&`
        selectedItems.map(function(item){
          queryParam += `ids[]=${item}&`
        })

  
        this.statusTarget.href = this.setStatusUrlValue + queryParam;
      } else {
        document.getElementById('batch-status-change').classList.add('hidden');
      }
    }

}
