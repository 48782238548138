import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['tab', 'panel']

  connect() {
    this.index = 0;
    this.hideAllTabs();
    this.selectTab();
  }

  change(event) {
    this.index = this.tabTargets.indexOf(event.currentTarget);

    this.hideAllTabs();

    this.selectTab(this.index);
  }

  selectTab() {
    this.tabTargets[this.index].classList.add('font-bold');
    this.panelTargets[this.index].classList.remove('hidden');
  }

  hideAllTabs() {
    this.tabTargets.forEach((item) => {
      item.classList.remove("font-bold");
    });

    this.panelTargets.forEach((item) => {
      item.classList.add("hidden");
    });
  }
}