import { Controller } from "stimulus"

export default class extends Controller {
  connect() {

    const user_country = document.querySelector('#user_country');
    if(user_country) {
      const choices3 = new Choices(user_country, {
        delimiter: ',',
        editItems: true,
        removeItemButton: true,
        placeholder: false,
      }); 
    }

    const custom_benefit_ids = document.querySelector('#custom_benefit_ids');
    if(custom_benefit_ids) {
      const choices3 = new Choices(custom_benefit_ids, {
        delimiter: ',',
        editItems: true,
        searchFields: ['label', 'value'],
        fuseOptions: {
          includeScore: true,
          useExtendedSearch: true,
          findAllMatches: true,
          ignoreLocation: true,
          fieldNormWeight: 0.6,
          distance: 500
        },
        removeItemButton: true,
        placeholder: false,
      }); 
    }

    const api_rewards = document.querySelector('#api_rewards');
    if(api_rewards) {
      const choices4 = new Choices(api_rewards, {
        delimiter: ',',
        editItems: true,
        searchFields: ['label', 'value'],
        fuseOptions: {
          includeScore: true,
          useExtendedSearch: true,
          findAllMatches: true,
          ignoreLocation: true,
          fieldNormWeight: 0.6,
          distance: 500
        },
        removeItemButton: true,
        placeholder: false,
      }); 
    }

    const hidden_benefit_ids = document.querySelector('#hidden_benefit_ids');
    if(hidden_benefit_ids) {
      const choices3 = new Choices(hidden_benefit_ids, {
        delimiter: ',',
        editItems: true,
        removeItemButton: true,
        placeholder: false,
        fuseOptions: {
          includeScore: true,
          useExtendedSearch: true,
          findAllMatches: true,
          ignoreLocation: true,
          fieldNormWeight: 0.6,
          distance: 500
        },
      }); 
    }

    const choicesCustomCategories = new Choices(
      document.querySelector('.js-select'), {
        callbackOnCreateTemplates: function(template) {
          let classNames = this.config.classNames;
          let itemSelectText = this.config.itemSelectText;
          return {
            choice: function(classNames, data) {
              return template(`<div class="${String(classNames.item)} ${String(classNames.itemChoice)} ${String(data.disabled ? classNames.itemDisabled : classNames.itemSelectable)}"
                                data-select-text="${String(itemSelectText)}"
                                data-choice ${String(data.disabled ? 'data-choice-disabled aria-disabled=true' : 'data-choice-selectable')}
                                data-id="${String(data.id)}"
                                data-value="${String(data.value)}"
                                data-label="${String(data.label)}"
                                ${String(data.groupId > 0 ? 'role="treeitem"' : 'role="option"')}>
                                    <img src="/covers/${String(data.value)}" alt="">
                                    ${String(data.label)}
                                </div>`)
            }
          }
        }
      });
    
    flatpickr(".flatpickr", {
    });
  }
}