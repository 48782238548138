import { Controller } from "stimulus"

export default class extends Controller {
  showAll() {
    const items = this.element.querySelectorAll('.c-benefits-sidebar__hidden');
    items.forEach((item)=> {
      item.classList.remove('c-benefits-sidebar__hidden')
    });

    this.element.querySelector('.c-benefits-sidebar__show-all').style.display = 'none';
  }
}