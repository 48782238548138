import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const contact_brand = document.querySelector('#contact_brand');
    this.element.focus();
    document.getElementById('benefit-app').setAttribute('aria-disabled', true);
    document.getElementById('benefit-app').setAttribute('aria-hidden', true);
    document.getElementById("benefit-app").tabIndex = -1;
    document.getElementById("benefit-app").setAttribute('disabled', 'disabled');

    if(contact_brand) {
      new Choices(contact_brand, {
        labelId: 'contact_brand_label'
      }); 
    }
  }

  closeModal(event) {
    let backUrl = this.element.getAttribute('data-back-url')
    if(event.target.classList.contains('c-modal--open')) {
      if(document.referrer.length > 0 && document.referrer != window.location.href ) {
        Turbo.visit(backUrl);
      } else {
        Turbo.visit(backUrl);
      }
    }
    
  }
}