import { Controller } from "stimulus"

export default class extends Controller {
  showModal() {
    const elem = this.element;
    elem.style.display = 'block';
  }

  closeModal() {
    const elem = this.element;
    elem.style.display = 'none';
  }
}