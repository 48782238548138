import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    //let width = document.getElementById('mobile-menu').offsetWidth;
    //document.getElementById('mobile-menu-items').style.width = `${width+3}px`;
  }

  toggleMenu() {
    this.element.classList.toggle('bg-white')
    document.getElementById('mobile-menu-items').classList.toggle('hidden');
    document.querySelector('.icon-hamburger-on').classList.toggle('hidden');
    document.querySelector('.icon-hamburger').classList.toggle('hidden');
  }

}