import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['tab', 'panel']

  connect() {
  }

  toggle() {
    document.getElementById('mobilemenu').classList.toggle('sm:hidden');
  }
}