import { Controller } from 'stimulus';

export default class extends Controller {
  openConfirmationModal() {
  }

  showPin() {
    this.element.querySelector('.c-modal').classList.add('c-modal--open');
  }

  hideModal() {
    this.element.querySelector('.c-modal').classList.remove('c-modal--open');
  }
}