import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['tab', 'panel']

  connect() {
    console.log(this.tabTargets);
    
    this.index = 0;
    this.hideAllTabs();
    this.selectTab();
  }

  change(event) {
    this.index = this.tabTargets.indexOf(event.currentTarget);

    this.hideAllTabs();

    this.selectTab(this.index);
  }

  selectTab() {
    //this.tabTargets[this.index].classList.add('font-bold');
    this.tabTargets[this.index].classList.add('text-primaryBase');
    //this.tabTargets[this.index].classList.add('border-b-2'); */
    this.panelTargets[this.index].classList.remove('hidden');

    this.tabTargets[this.index].classList.add('after:border-primaryBase');
    this.tabTargets[this.index].classList.add('after:flex');
    this.tabTargets[this.index].classList.add('after:bg-primaryBase');
    this.tabTargets[this.index].classList.add('after:w-full');
    this.tabTargets[this.index].classList.add('after:content-[&quot;&quot;]');
    this.tabTargets[this.index].classList.add('after:h-[1px]');
    this.tabTargets[this.index].classList.add('after:absolute');
    this.tabTargets[this.index].classList.add('after:-bottom-[12px]');
    this.tabTargets[this.index].classList.add('after:left-0');
  }

  hideAllTabs() {
    this.tabTargets.forEach((item) => {
      //item.classList.remove("font-bold");
      item.classList.remove("text-primaryBase");

      item.classList.remove('after:border-primaryBase');
      item.classList.remove('after:flex');
      item.classList.remove('after:bg-primaryBase');
      item.classList.remove('after:w-full');
      item.classList.remove('after:content-[&quot;&quot;]');
      item.classList.remove('after:h-[1px]');
      item.classList.remove('after:absolute');
      item.classList.remove('after:-bottom-[12px]');
      item.classList.remove('after:left-0');
      //item.classList.remove("border-b-2"); */
    });

    this.panelTargets.forEach((item) => {
      item.classList.add("hidden");
    });
  }
}