import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    flatpickr(".flatpickr", {
    });
    
    const element = document.querySelector('#reward_category_selected_rewards');
    if(element) {
      const choices = new Choices(element, {
        delimiter: ',',
        editItems: true,
        removeItemButton: true,
        placeholder: false,
        fuseOptions: {
          includeScore: true,
          useExtendedSearch: true,
          findAllMatches: true,
          ignoreLocation: true,
          fieldNormWeight: 0.6,
          distance: 500
        },
      }); 
    }

    const reward_employee_selected_rewards = document.querySelector('#reward_employee_selected_rewards');
    if(reward_employee_selected_rewards) {
      const choices = new Choices(reward_employee_selected_rewards, {
        delimiter: ',',
        editItems: true,
        removeItemButton: true,
        placeholder: false,
        fuseOptions: {
          includeScore: true,
          useExtendedSearch: true,
          findAllMatches: true,
          ignoreLocation: true,
          fieldNormWeight: 0.6,
          distance: 500
        },
      }); 
    }
  }
}