import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "whiteLogo", "inverseLogo", "menu", "menuInverse" ]
  connect() {
    window.onscroll = function() {myFunction()};

    console.log(this.whiteLogoTarget)
    // Get the header
    let header = this.element;
    let width = document.getElementById('landing-header').width;
    
    // Get the offset position of the navbar
    let sticky = header.offsetTop;
    let _this = this;

    
    // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
    function myFunction() {
      if(window.innerWidth > 600) {
        if (window.pageYOffset > 100) {
          header.classList.add("bg-white");
          _this.whiteLogoTarget.classList.add('hidden');
          _this.inverseLogoTarget.classList.remove('hidden');
          _this.menuTarget.classList.add('hidden');
          _this.menuInverseTarget.classList.remove('hidden');
        } else {
          header.classList.remove("bg-white");
          _this.whiteLogoTarget.classList.remove('hidden');
          _this.inverseLogoTarget.classList.add('hidden');
          _this.menuTarget.classList.remove('hidden');
          _this.menuInverseTarget.classList.add('hidden');
        }

      }
    }
  }
}