import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    document.getElementById("import_file").onchange = function() {
      document.getElementById("submit_import").click();
    };
  }

  showModal() {
    const elem = document.getElementById(`import-modal`);
    elem.style.display = 'block';
  }

  closeModal() {
    const elem = document.getElementById(`import-modal`);
    elem.style.display = 'none';
  }
}