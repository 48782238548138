import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ "source" ]

  closeModal(event) {
    const url = this.element.getAttribute('data-back-url')
    if(event.target.classList.contains('c-modal--open')) {
      if(document.referrer.length > 0 && document.referrer != window.location.href && document.referrer.indexOf('my_profile') > -1 ) {
        Turbo.visit(document.referrer);
      } else {
        Turbo.visit(url);
      }
    }
    
  }

  showPin() {
    this.element.querySelector('.c-modal--pin').classList.add('c-modal--open');
  }

  hideModal() {
    this.element.querySelector('.c-modal--pin').classList.remove('c-modal--open');
  }

  copy(event) {
    event.target.innerText = 'Copied!';
    const el = document.createElement('textarea');
    console.log(this.sourceTarget.innerText);
    el.value = this.sourceTarget.innerText;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');

    let id = this.element.getAttribute('data-redemptiom-id');
    window.Rails.ajax({
      url: `/benefits/redemptions/${id}`,
      type: "patch",
      data: `has_copied=true`,
      success: function(data) {
        console.log('saved');
      },
      error: function(data) {

      }
    });
  }

  openWebsite() {
    let id = this.element.getAttribute('data-redemptiom-id');
    window.Rails.ajax({
      url: `/benefits/redemptions/${id}`,
      type: "patch",
      data: `has_opened=true`,
      success: function(data) {
        console.log('saved');
      },
      error: function(data) {

      }
    });
  }
}